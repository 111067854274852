import {
  isRemoteDataError,
  isRemoteDataOK,
  RemoteData,
} from '@common/util-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  REINSTATE_FEATURE_KEY,
  ReinstatePartialState,
  ReinstateState,
} from './reinstate.reducer';

export const getReinstateState = createFeatureSelector<ReinstateState>(
  REINSTATE_FEATURE_KEY
);

export const getReinstateRemoteState = createSelector(
  getReinstateState,
  (state: ReinstateState) => state.remoteState
);

export const getReinstateForm = createSelector(
  getReinstateState,
  (state: ReinstateState) => state.formData
);

export const getReinstateError = createSelector(
  getReinstateRemoteState,
  (remoteState: RemoteData) => {
    if (isRemoteDataError(remoteState)) {
      return remoteState.error;
    }
    return undefined;
  }
);

export const isReinstateSuccess = createSelector(
  getReinstateRemoteState,
  (remoteState: RemoteData) => isRemoteDataOK(remoteState)
);

export const isReinstateError = createSelector(
  getReinstateRemoteState,
  (remoteState: RemoteData) => isRemoteDataError(remoteState)
);

export const getReinstateValidationFailed = createSelector(
  getReinstateState,
  (state: ReinstateState) => state.validationFailed
);
