<ui-card
  *ngIf="product"
  [selected]="selected"
  [ngClass]="{ selectable: selectable }"
  (click)="onClick()"
>
  <ui-card-header>
    <ui-card-title *ngIf="product.title">{{ product.title }}</ui-card-title>
    <ui-card-radio
      [selected]="selected"
      (valueChange)="isSelected()"
    ></ui-card-radio>
  </ui-card-header>
  <ui-card-body>
    <div class="price-container">
      <div class="monthlyPrice">
        <span class="monthlyPriceTitle"> Monthly cost </span>
        <div *ngIf="product?.discount">
          £{{ product.discountedMonthlyPrice | number: '1.2-2' }}
          {{ product.discountTerm }}
        </div>
        £{{ product.perMonthPrice | number: '1.2-2' }} <br />
      </div>
      <span *ngIf="product?.discount"> {{ product.subsequentTerm }}</span>

      <div class="yearlyPrice">
        <span class="yearlyPriceTitle"> Total cost over 12 months </span>
        £{{ product.yearlyPrice | number: '1.2-2' }}
      </div>
      <div *ngIf="product.extraCost" class="extraCost">
        <div *ngIf="product?.discountedExtraCost">
          + £{{ product.discountedExtraCost | number: '1.2-2' }} per Month
          {{ product.discountTerm }}
        </div>
        + £{{ product.extraCost | number: '1.2-2' }} per Month
        <span *ngIf="product?.discountedExtraCost">
          {{ product.subsequentTerm }}</span
        >
      </div>
    </div>
    <div class="desc" *ngIf="product.desc">{{ product.desc }}</div>
  </ui-card-body>
</ui-card>
