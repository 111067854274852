import { Injectable } from '@angular/core';
import { ReinstateFormSubmit } from '@common/util-models';
import { filterNullUndefined } from '@domgen/dgx-fe-common';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as ReinstateActions from './reinstate.actions';
import { ReinstatePartialState } from './reinstate.reducer';
import * as ReinstateSelectors from './reinstate.selectors';

@Injectable()
export class ReinstateFacade {
  formData$: Observable<ReinstateFormSubmit> = this.store.pipe(
    select(ReinstateSelectors.getReinstateForm),
    filterNullUndefined()
  );

  error$: Observable<Error> = this.store.pipe(
    select(ReinstateSelectors.getReinstateError),
    filterNullUndefined()
  );

  isError$: Observable<boolean> = this.store.pipe(
    select(ReinstateSelectors.isReinstateError)
  );

  isSuccess$: Observable<boolean> = this.store.pipe(
    select(ReinstateSelectors.isReinstateSuccess)
  );

  reinstateValidationFailed$: Observable<boolean> = this.store.pipe(
    select(ReinstateSelectors.getReinstateValidationFailed),
    map((reinstateValidation) => !!reinstateValidation)
  );

  constructor(private store: Store<ReinstatePartialState>) {}

  reinstateDirectDebit(formData: ReinstateFormSubmit) {
    this.store.dispatch(
      ReinstateActions.reinstateDirectDebit({
        formData,
        reinstateRequest: {
          email: formData.email,
          last_name: formData.lastName,
          plan_number: formData.planNumber,
          postcode: formData.postCode,
        },
      })
    );
  }
}
