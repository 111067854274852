import { Component, Input } from '@angular/core';

@Component({
  selector: 'dgx-uib-benefits-coverage',
  templateUrl: './benefits-coverage.component.html',
  styleUrls: ['./benefits-coverage.component.scss'],
})
export class BenefitsCoverageComponent {
  @Input() includedTitle = ``;
  @Input() included: string[] = [];
  @Input() excludedTitle = ``;
  @Input() excluded: string[] = [];
  @Input() deviceExcluded: string | undefined;

  // Default titles if we don't pass any
  readonly DEFAULT_INCLUDED_TITLE: string = "What's included";
  readonly DEFAULT_NOT_INCLUDED_TITLE: string = "What's not included?";
}
