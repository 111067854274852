import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';
import { BenefitsCoverageComponent } from './benefits-coverage.component';

@NgModule({
  imports: [CommonModule, SharedUiComponentsCoreModule],
  declarations: [BenefitsCoverageComponent],
  exports: [BenefitsCoverageComponent],
})
export class BenefitsCoverageModule {}
