<div>
  <div>
    <div class="benefits p-5">
      <h3>{{ includedTitle || DEFAULT_INCLUDED_TITLE }}</h3>
      <ng-container *ngTemplateOutlet="includedBenefits" ;></ng-container>
    </div>

    <div class="benefits p-5 mt-6">
      <h3>{{ excludedTitle || DEFAULT_NOT_INCLUDED_TITLE }}</h3>
      <ng-container *ngTemplateOutlet="excludedBenefits" ;></ng-container>
    </div>
  </div>
  <div *ngIf="deviceExcluded" class="stack-view mt-6">
    <p class="text--center">{{ deviceExcluded }}</p>
  </div>
</div>

<ng-template #includedBenefits>
  <ul class="list">
    <li class="list__item" *ngFor="let includedItem of included">
      <div class="icon-check">
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#228305"
            d="M7.34375 10.375C7.15625 10.5625 6.8125 10.5625 6.625 10.375L4.625 8.375C4.4375 8.1875 4.4375 7.84375 4.625 7.65625C4.8125 7.46875 5.15625 7.46875 5.34375 7.65625L7 9.3125L10.625 5.65625C10.8125 5.46875 11.1562 5.46875 11.3438 5.65625C11.5312 5.84375 11.5312 6.1875 11.3438 6.375L7.34375 10.375ZM16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8 1C4.125 1 1 4.15625 1 8C1 11.875 4.125 15 8 15C11.8438 15 15 11.875 15 8C15 4.15625 11.8438 1 8 1Z"
          />
        </svg>
      </div>
      <p>{{ includedItem }}</p>
    </li>
  </ul>
</ng-template>

<ng-template #excludedBenefits>
  <ul class="list">
    <li class="list__item" *ngFor="let excludedItem of excluded">
      <div class="icon-cross">
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM8 15C11.8438 15 15 11.875 15 8C15 4.15625 11.8438 1 8 1C4.125 1 1 4.15625 1 8C1 11.875 4.125 15 8 15ZM11.3125 4.125C11.5312 4.3125 11.5312 4.625 11.375 4.84375L8.65625 8L11.375 11.1875C11.5312 11.4062 11.5312 11.7188 11.3125 11.9062C11.2188 11.9688 11.0938 12 11 12C10.8438 12 10.7188 11.9688 10.5938 11.8438L8 8.78125L5.375 11.8438C5.25 11.9688 5.125 12 5 12C4.875 12 4.75 11.9688 4.65625 11.9062C4.4375 11.7188 4.4375 11.4062 4.59375 11.1875L7.3125 8L4.59375 4.84375C4.4375 4.625 4.4375 4.3125 4.65625 4.125C4.875 3.96875 5.1875 3.96875 5.375 4.1875L8 7.25L10.5938 4.1875C10.7812 3.96875 11.0938 3.96875 11.3125 4.125Z"
            fill="#A12221"
          />
        </svg>
      </div>
      {{ excludedItem }}
    </li>
  </ul>
</ng-template>
