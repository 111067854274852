import { BuildConfig } from '@common/util-models';
import { NavigationLink } from '@domgen/dgx-fe-business-models';

const headerLinks: BuildConfig['header'] = {
  home: {
    href: 'https://www.domesticandgeneral.com',
    label: 'home',
  },
  products: {
    label: 'Get protected',
    href: 'https://www.sales.athome.domesticandgeneral.com/products',
  },
  repair: {
    label: 'Book a repair',
    href: 'https://www.domesticandgeneral.com/repairs',
  },
  faq: {
    label: 'Help and advice',
    href: 'https://www.domesticandgeneral.com/content/help-advice-section/faq',
  },
};
const privacyLink = {
  section: 'legal',
  label: 'Privacy and Cookies policy',
  href: 'https://www.domesticandgeneral.com/privacy-cookies-policy',
  newWindow: true,
};

const footerLinks: NavigationLink[] = [
  {
    section: 'protect',
    label: 'Household appliances',
    href: 'https://domesticandgeneral.com/shop/en/dg/products/household',
    newWindow: true,
  },
  {
    section: 'protect',
    label: 'Washing machine',
    href: 'https://www.domesticandgeneral.com/insurance/washing-machine',
    newWindow: true,
  },
  {
    section: 'protect',
    label: 'Dishwasher',
    href: 'https://www.domesticandgeneral.com/insurance/dishwasher',
    newWindow: true,
  },
  {
    section: 'protect',
    label: 'Fridge freezer',
    href: 'https://www.domesticandgeneral.com/insurance/fridge-freezer',
    newWindow: true,
  },
  {
    section: 'protect',
    label: 'Tumble dryer',
    href: 'https://www.domesticandgeneral.com/insurance/tumble-dryer',
    newWindow: true,
  },
  {
    section: 'protect',
    label: 'Oven',
    href: 'https://www.domesticandgeneral.com/insurance/oven',
    newWindow: true,
  },
  {
    section: 'protect',
    label: 'Heating',
    href: 'https://domesticandgeneral.com/shop/en/dg/heating',
    newWindow: true,
  },
  {
    section: 'protect',
    label: 'TV & Audio',
    href: 'https://domesticandgeneral.com/shop/en/dg/products/tv-audio',
    newWindow: true,
  },
  {
    section: 'protect',
    label: 'Photo & Video devices',
    href: 'https://domesticandgeneral.com/shop/en/dg/products/camera-mobile',
    newWindow: true,
  },

  {
    section: 'more',
    label: 'Get protected',
    routerPath: '/products',
  },
  {
    section: 'more',
    label: 'Book a repair',
    href: 'https://domesticandgeneral.com/appliance-repairs',
    newWindow: true,
  },
  {
    section: 'more',
    label: 'About us',
    href: 'https://domesticandgeneral.com/corporate/about-us',
    newWindow: true,
  },
  {
    section: 'more',
    label: 'Help and advice',
    href: 'https://domesticandgeneral.com/content/help-advice-section/faq',
    newWindow: true,
  },
  {
    section: 'more',
    label: 'Contact us',
    href: 'https://www.domesticandgeneral.com/content/contact-domestic-general',
    newWindow: true,
  },
  {
    section: 'more',
    label: 'Media centre',
    href: 'https://www.domesticandgeneral.com/content/contact-domestic-general/corporate-information/media-centre',
    newWindow: true,
  },
  {
    section: 'more',
    label: 'Careers',
    href: 'https://careers.domesticandgeneral.com/',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Investor relations',
    href: 'https://investors.domesticandgeneral.com/',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Regulatory information',
    href: 'https://domesticandgeneral.com/shop/en/dg/company-information-legal',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Charity Partnerships',
    href: 'https://www.domesticandgeneral.com/content/contact-domestic-general/corporate-information/media-centre',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Modern Slavery Statement',
    href: 'https://domesticandgeneral.com/shop/en/dg/modern-slavery',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Part VII Transfer',
    href: 'https://domesticandgeneral.com/PartVIITransfer',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Rogue Trader Guidance',
    href: 'https://domesticandgeneral.com/content/help-advice-section/faq/received-a-strange-call-',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Customer Conduct',
    href: 'https://domesticandgeneral.com/customer-conduct',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Coronavirus update',
    href: 'https://domesticandgeneral.com/content/help-advice-section/faq/coronavirus-covid19-update',
    newWindow: true,
  },
  {
    section: 'legal',
    label: 'Website Terms and Conditions',
    href: 'https://www.domesticandgeneral.com/web-terms-conditions',
    newWindow: true,
  },
  privacyLink,
  {
    section: 'legal',
    label: 'Accessibility policy',
    href: 'https://www.domesticandgeneral.com/accessibility',
    newWindow: true,
  },
];

export const DEFAULT_ENVIRONMENT = {
  header: headerLinks,
  footer: {
    links: footerLinks,
  },
  links: {
    privacy: privacyLink,
  },

  myAccountLink:
    'https://www.my-account.athome.domesticandgeneral.com/my-account',
  dd_guarantee: 'https://www.domesticandgeneral.com/direct-debit-guarantee',
  wlClient: 'DandGUK',
  catalogueStore: 'DandGUK',
  domainBasedRequestSources: [
    {
      pattern: /^local-www.sky-reinstate/,
      requestSource: 'SKYUK',
    },
    {
      pattern: /^localhost/,
      requestSource: 'DandGUK',
    },
  ],
  cookieProSrc: 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js',
  action: 'QandB',
  offerPage: '/offer',
  renewalPage: '/renewal',
  quotePage: '/quote',
  checkoutLandingPage: '/checkout',
  checkoutOrderConfirmationPage: '/checkout/order-confirmation',
  applianceDetailsPage: '/products',
  checkoutPersonalDetailsPage: '/checkout/personal-details',
  checkoutDirectDebitPage: '/checkout/direct-debit-payment',
  checkoutCardPaymentPage: '/checkout/card-payment',
  checkoutLoggedInUserPage: '/checkout/logged-in',
  selectPaymentPage: '/checkout/select-payment',
  forgotPasswordPage: '/forgot-password',
  cms_config: {
    pagesRoot: '/dgx-reinstate',
    insideCmsEditor: false,
    logCmsRequests: false,
    useCachedCmsOutput: false,
    useFallbackCmsContent: false,
    cachedRouteBase: 'assets/cached-cms/pages',
    cachedContentAppBase: 'assets/cached-cms/content-apps/',
  },
  trustPilot: {
    businessUnitId: '4cb3113600006400050dfb02',
    templates: [
      {
        id: '5419b6ffb0d04a076446a9af',
        name: 'micro',
      },
      {
        id: '54ad5defc6454f065c28af8b',
        name: 'slider',
      },
    ],
  },
  cardPayment: {
    description: 'Sales',
    transactionSource: 'W',
    transactionType: 'W',
  },
  retryBackOffConfig: {
    maxRetries: 1,
    initialInterval: 3000,
    maxInterval: 3000,
  },
  postcodeServiceKey: 'ZG99-WD62-FP46-DC55',
  postcodeServiceFind:
    'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws',
  postcodeServiceRetrieve:
    'https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws',
  worldpay: {
    customisation: {
      fonts: {
        family: ['Museo Sans', 'Source Sans Pro', 'Arial'],
      },
      color: '#414141',
      buttons: {
        backgroundColor: '#038ad3',
        color: '#fff',
        border: {
          style: 'solid',
          color: '#038ad3',
          width: '5px',
          radius: '2px',
        },
        hover: {
          backgroundColor: '#0071bb',
          border: {
            color: '#0071bb',
          },
        },
      },
      inputs: {
        backgroundColor: '#ffffff',
        border: {
          style: 'solid',
          width: '1px',
          radius: '2px',
          color: '#757575',
        },
        placeholder: {
          color: '#001',
        },
        validation: {
          ok: {
            color: '#228415',
          },
          error: {
            color: '#af2c2b',
          },
        },
      },
      page: {
        backgroundColor: '#fff',
        color: '#333',
        border: {
          style: 'solid',
          color: '#ddd',
          width: '1px',
          radius: '2px',
        },
      },
    },
  },
  error404: {
    contactUs:
      'https://www.domesticandgeneral.com/content/contact-domestic-general',
    home: 'reinstate',
  },
  appRoot: '/',
};
