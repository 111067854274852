import { CmsModuleConfig } from '@domgen/dgx-fe-content-management';

import {
  ApplianceFormSubmit,
  BenefitsCoverageV2ClientConfig,
  CheckoutPlanStartDateClientConfig,
  ErrorPageData,
  SafeHandsClientConfig,
  TrustBoxClientConfig,
  WorkingOrderErrorMessage,
} from '.';
import {
  ApplianceFormField,
  FixedFormValues,
} from './appliance-form-cms.types';
import { CoverType, CoverTypeName } from './basket-item.types';
import { JourneyType } from './journey-type.enum';
import { UpgradeCoverConfig } from './quote-page.types';

export interface CoverTypeUpgrade {
  coverType: CoverType;
  description?: string;
}

export interface QuotesConfig {
  alternateProductUpgrades?: Partial<Record<CoverType, UpgradeCoverConfig>>;
  coverExclusionDisplayType?: 'tabs' | 'stacked';
  coverTypeDescription?: Partial<Record<CoverType, string>>;
  coverTypeNames?: Partial<Record<CoverType, CoverTypeName>>;
  coverTypeUpgrades: Partial<Record<CoverType, CoverTypeUpgrade[]>>;
  coverTypesWhitelist: CoverType[] | false;
  showAllCoverTypes: boolean;
}

export interface ApplianceDetailsInWarrantySalesConfig {
  default: boolean;
  applianceCategories?: {
    [key: string]: boolean;
  };
}

export interface ApplianceDetailsConfig {
  applianceDetailsFormData?: ApplianceFormSubmit;
  controlTypes?: Partial<Record<ApplianceFormField, string>>;
  fixedValues?: FixedFormValues;
  formControlsToHide?: ApplianceFormField[];
  maxAge?: number;
  preselectedBrandCode?: string;
  showInWarrantyStopMsg: ApplianceDetailsInWarrantySalesConfig;
  maxGuaranteeDuration?: number;
  minGuaranteeDuration?: number;
}

export interface JourneyTypeConfig {
  engineer: boolean;
  heating: boolean;
  mobile: boolean;
  whitegoods: boolean;
}

export interface MentionMeConfig {
  locale: string;
  situation: string;
}

export interface DiscountStoreConfig {
  discount: number;
}

export interface LoadFeatureConfigResponse {
  applianceDetails?: ApplianceDetailsConfig;
  clientConfig?: ClientConfig;
  discountStore?: DiscountStoreConfig;
  quotes?: QuotesConfig;
}
export interface ModalContentConfig {
  title: string;
  body: string;
  primaryText: string;
  secondaryText?: string;
  disableClose?: boolean;
}

export interface ClientConfig {
  clientName: string;
  clientLogoPath: string;
  cms?: CmsModuleConfig;
  errorPage?: ErrorPageData;
  workingOrderErrorMessage?: WorkingOrderErrorMessage;
  gaClientIdentifier: string;
  journeyType?: JourneyTypeConfig;
  mentionMe?: MentionMeConfig;
  policyFeaturesClientIdentifier: string;
  safeHands: SafeHandsClientConfig;
  trustBox: TrustBoxClientConfig;
  faqDisabled: boolean;
  checkoutPlanStartDate: CheckoutPlanStartDateClientConfig;
  benefitsCoverageV2: BenefitsCoverageV2ClientConfig;
  whyGetProtectedPath: WhyGetProtectedPathType;
  terms: string;
}

export interface ReinstateClientConfig extends ClientConfig {
  confirmation: string;
  footer: string;
  heading: string;
  homePageRedirectUrl: string;
  subheading: string;
  thankYouFooter: string;
  validationFailure: string;
}

export type WhyGetProtectedPathType =
  | 'why_get_protected'
  | 'why_get_protected_DISC10'
  | 'why_get_protected_heating'
  | 'why_get_protected_whitegoods';

export function getJourneyType(
  clientConfig: ClientConfig | undefined
): JourneyType {
  if (clientConfig?.journeyType?.engineer) {
    return JourneyType.Engineer;
  } else if (clientConfig?.journeyType?.mobile) {
    return JourneyType.Mobile;
  } else {
    return JourneyType.Sales;
  }
}
