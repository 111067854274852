export const COVERS_EXCLUSIONS = {
  results: [
    {
      '@name': 'policy-features-1',
      '@path': '/policy-features-1',
      '@id': 'fa7f97bc-7b89-45d0-82d0-acc67df356e0',
      '@nodeType': 'policy-feature',
      included: [
        'Accidental damage cover.',
        'A replacement appliance if yours has a future breakdown which can’t be fixed.',
        'Repairs by expert engineers to get you back up and running as quickly as possible.',
        'No hidden costs for parts, call-outs and labour for any mechanical or electrical fault.',
        'Exclusive 24/7 access to our Appliance Care portal.',
        'Ongoing help and support including a remote annual health check for your appliance.',
      ],
      excluded: [
        'Any forceful or deliberate damage neglect.',
        'Any costs incurred at not being able to use your product.',
        'Loss, theft or attempted theft.',
        'Using your product in a workplace.',
      ],
      termsAndConditionsAsset: {
        '@name': 'WHI_TCWIM_1118_21_WEB.pdf',
        '@path': '/pdf/WHI_TCWIM_1118_21_WEB.pdf',
        '@id': 'jcr:8849434e-8c46-4bae-a709-4fd031cf7215',
        '@link':
          '/dam/jcr:8849434e-8c46-4bae-a709-4fd031cf7215/WHI_TCWIM_1118_21_WEB.pdf',
        metadata: {
          fileName: 'WHI_TCWIM_1118_21_WEB.pdf',
          mimeType: 'application/pdf',
          fileSize: '110435',
          height: '0',
          width: '0',
          title: 'Policy Terms & Conditions',
          format: 'application/pdf',
          creator: ['superuser'],
          date: '2020-12-10T00:49:01.229Z',
          created: '2020-12-10T00:49:01.229Z',
          modified: '2020-12-10T00:49:01.229Z',
        },
      },
      ipidAsset: {
        '@name': 'DG_IIPM_0418_06.pdf',
        '@path': '/pdf/DG_IIPM_0418_06.pdf',
        '@id': 'jcr:15478cf7-8b5b-4573-8c8d-c989012d2329',
        '@link':
          '/dam/jcr:15478cf7-8b5b-4573-8c8d-c989012d2329/DG_IIPM_0418_06.pdf',
        metadata: {
          fileName: 'DG_IIPM_0418_06.pdf',
          mimeType: 'application/pdf',
          fileSize: '84038',
          height: '0',
          width: '0',
          title: 'Insurance Product Information Document',
          format: 'application/pdf',
          creator: ['superuser'],
          date: '2020-12-10T00:49:25.527Z',
          created: '2020-12-10T00:48:28.290Z',
          modified: '2020-12-10T00:49:25.527Z',
        },
      },
      jcrName: 'policy-features-1',
      formName: 'policy-features-1',
      banner: 'Good news! We can protect your appliance',
      planDurationHeading: 'Your 12-month plan',
      planDurationBody: `If your boiler is in guarantee, the plan starts at the end of the guarantee and lasts for 12 months.
      If it’s not in guarantee, the plan starts once we have processed your application.
      Paying by Direct Debit ensures automatic renewal each year.
      Don’t worry, we’ll write to you before your renewal date.`,
      '@nodes': [],
    },
    {
      '@name': 'heating-policy-features',
      '@path': '/heating-policy-features',
      '@id': '734cf715-e2ee-423f-bbc3-33dc1d205119',
      '@nodeType': 'dgx-policy-feature',
      included: [
        'Ongoing maintenance hints and tips to keep your boiler in good working order',
        'Annual service to help maintain your boiler',
        'Exclusive access to our BoilerCare portal',
        'Repairs up to the value of £1,500 if your boiler fails the annual service or stops working',
        'Repairs and annual services by expert Gas Safe engineers',
        "A replacement boiler up to the value of £750 if yours can't be fixed (pay the cost of installation and disposal)",
      ],
      ipidAsset: {
        '@name': 'DG_IIPM_0418_06.pdf',
        '@path': '/dgx-sales/pdf/DG_IIPM_0418_06.pdf',
        '@id': 'jcr:15478cf7-8b5b-4573-8c8d-c989012d2329',
        '@link':
          '/dam/jcr:15478cf7-8b5b-4573-8c8d-c989012d2329/DG_IIPM_0418_06%20(1).pdf',
        metadata: {
          fileName: 'DG_IIPM_0418_06 (1).pdf',
          mimeType: 'application/pdf',
          fileSize: '84038',
          height: '0',
          width: '0',
          title: 'Insurance Product Information Document',
          format: 'application/pdf',
          creator: ['richardenglish'],
          date: '2020-12-10T14:22:10.371Z',
          created: '2020-12-10T14:20:19.425Z',
          modified: '2020-12-10T14:22:10.371Z',
        },
      },
      termsAndConditionsAsset: {
        '@name': 'DG_WTCHE_1118_29.pdf',
        '@path': '/dgx-sales/pdf/DG_WTCHE_1118_29.pdf',
        '@id': 'jcr:2790c839-1da2-4638-a897-57a283082681',
        '@link':
          '/dam/jcr:2790c839-1da2-4638-a897-57a283082681/DG_WTCHE_1118_29.pdf',
        metadata: {
          fileName: 'DG_WTCHE_1118_29.pdf',
          mimeType: 'application/pdf',
          fileSize: '123399',
          height: '0',
          width: '0',
          title: 'Plan Terms and Conditions',
          format: 'application/pdf',
          description: 'Heating Appliance (Boiler) Ts&Cs',
          creator: ['denislisitsyn'],
          date: '2021-03-10T09:55:13.705Z',
          created: '2021-02-09T11:03:17.039Z',
          modified: '2021-03-10T09:55:13.705Z',
        },
      },
      banner: 'Good news! We can protect your appliance',
      planDurationBody:
        "If your boiler is in guarantee, the plan starts at the end of the guarantee and lasts for 12 months. If it's not in guarantee, the plan starts once we have processed your application. Paying by Direct Debit ensures automatic renewal each year. Don't worry, we'll write to you before your renewal date.",
      jcrName: 'heating-policy-features',
      excluded: [
        'Damage of any kind to your boiler or other heating equipment, including accidental, cosmetic or malicious damage',
        'Sludges, blockages or hard water deposits (i.e. calcium)',
        'Costs incurred as a result of not being able to use your boiler or other heating equipment',
        'LPG, oil, and electric heating systems as well as flue systems from the boiler',
        'Pre-existing faults or problems or any work to upgrade your boiler or system to meet current industry standards',
      ],
      planDurationHeading: 'Your 12-month plan',
      '@nodes': [],
    },
  ],
};
