import {
  ApplianceDetailsConfig,
  BenefitsCoverageV2ClientConfig,
  CheckoutPlanStartDateClientConfig,
  ClientConfig,
  DiscountStoreConfig,
  ErrorPageData,
  getJourneyType,
  isRemoteDataError,
  isRemoteDataOK,
  JourneyType,
  QuotesConfig,
  RemoteData,
  SafeHandsClientConfig,
  TrustBoxClientConfig,
  WorkingOrderErrorMessage,
} from '@common/util-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  FEATURE_CONFIG_FEATURE_KEY,
  FeatureConfigState,
} from './feature-config.reducers';

export const getFeatureConfigState = createFeatureSelector<
  FeatureConfigState | undefined
>(FEATURE_CONFIG_FEATURE_KEY);

export const getApplianceDetailsConfig = createSelector(
  getFeatureConfigState,
  (state?: FeatureConfigState): ApplianceDetailsConfig | undefined =>
    state?.applianceDetails
);

export const getQuotesConfig = createSelector(
  getFeatureConfigState,
  (state?: FeatureConfigState): QuotesConfig | undefined => state?.quotes
);

export const getDiscountStoreConfig = createSelector(
  getFeatureConfigState,
  (state?: FeatureConfigState): DiscountStoreConfig | undefined =>
    state?.discountStore
);

export const getRemoteState = createSelector(
  getFeatureConfigState,
  (state?: FeatureConfigState): RemoteData | undefined => state?.remoteState
);

export const getRemoteStateLoaded = createSelector(
  getRemoteState,
  (remoteState: RemoteData | undefined): boolean =>
    !!remoteState &&
    (isRemoteDataOK(remoteState) || isRemoteDataError(remoteState))
);

export const getClientConfig = createSelector(
  getFeatureConfigState,
  (state?: FeatureConfigState): ClientConfig | undefined => state?.clientConfig
);

export const getConfigJourneyType = createSelector(
  getClientConfig,
  (clientConfig: ClientConfig | undefined): JourneyType =>
    getJourneyType(clientConfig)
);

export const isEngineerJourney = createSelector(
  getClientConfig,
  (clientConfig: ClientConfig | undefined): boolean =>
    !!clientConfig?.journeyType?.engineer
);

export const isMobileJourney = createSelector(
  getClientConfig,
  (clientConfig: ClientConfig | undefined): boolean =>
    !!clientConfig?.journeyType?.mobile
);

export const getTrustBoxClientConfig = createSelector(
  getClientConfig,
  (clientConfig: ClientConfig | undefined): TrustBoxClientConfig | undefined =>
    clientConfig?.trustBox
);

export const getIsFaqDisabledClientConfig = createSelector(
  getClientConfig,
  (clientConfig: ClientConfig | undefined): boolean | undefined =>
    clientConfig?.faqDisabled
);

export const getCheckoutPlanStartDateClientConfig = createSelector(
  getClientConfig,
  (
    clientConfig: ClientConfig | undefined
  ): CheckoutPlanStartDateClientConfig | undefined =>
    clientConfig?.checkoutPlanStartDate
);

export const getBenefitsCoverageV2ClientConfig = createSelector(
  getClientConfig,
  (
    clientConfig: ClientConfig | undefined
  ): BenefitsCoverageV2ClientConfig | undefined =>
    clientConfig?.benefitsCoverageV2
);

export const getSafeHandsClientConfig = createSelector(
  getClientConfig,
  (clientConfig: ClientConfig | undefined): SafeHandsClientConfig | undefined =>
    clientConfig?.safeHands
);

export const getErrorPageClientConfig = createSelector(
  getClientConfig,
  (clientConfig: ClientConfig | undefined): ErrorPageData | undefined =>
    clientConfig?.errorPage
);

export const getWorkingOrderErrorMessageClientConfig = createSelector(
  getClientConfig,
  (
    clientConfig: ClientConfig | undefined
  ): WorkingOrderErrorMessage | undefined =>
    clientConfig?.workingOrderErrorMessage
);
